import React from 'react';
import Header from './components/Header';
// import Features from './components/Features';
// import Pricing from './components/Pricing';
// import Sponsors from './components/Sponsors';
// import Footer from './components/Footer';

function App() {
  return (
    <>
      <main>
        <Header />
        {/* <Features />
        <Pricing />
        <Sponsors />
        <Footer /> */}
      </main>
    </>
  );
}

export default App;
